<template>
  <div>
    <Banner
      :banner_img="resBanners"
      banner_en="NOTICE OF INVITATION FOR BIDS"
      banner_cn="招标公告"
    ></Banner>
    <div class="mainBodyA clear">
      <div class="noticeDetails">
        <div class="detailcon fl">
          <div class="detailcon_tit">
            {{
              infoObj.title +
              (infoObj.itemNo ? "(项目编号" + infoObj.itemNo + ")" : "")
            }}
          </div>
          <div class="detailcon_time fontsize16 color666">
            发布时间：{{ infoObj.releaseTime }}
          </div>
          <!-- <div class="detailcon_con marginT20"> -->
          <div
            class="detailcon_con marginT20 ql-snow ql-editor"
            v-html="infoObj.content"
          ></div>
          <div class="filecon marginT40">
            <div
              v-for="(item, index) in infoObj.fileUrl"
              :key="index"
              class="detail_file clear"
            >
              <div class="fl detail_file_tit">附件</div>

              <a target="_blank" download :href="fileUrlHeader + item.url">
                <div class="fl detail_file_f">
                  {{ item.name }}
                </div>
              </a>
            </div>
          </div>
          <div class="c-footer clear">
            <div class="f-left">
              <router-link
                :to="
                  '/notice-details?id=' +
                  infoObj.prev.id +
                  '&index=' +
                  infoObj.prev.index +
                  '&type=' +
                  $route.query.type
                "
                v-if="infoObj.prev"
              >
                上一条：{{
                  infoObj.prev.title +
                  (infoObj.prev.itemNo
                    ? "(项目编号" + infoObj.prev.itemNo + ")"
                    : "")
                }}
              </router-link>
              <router-link
                :to="
                  '/notice-details?id=' +
                  infoObj.next.id +
                  '&index=' +
                  infoObj.next.index +
                  '&type=' +
                  $route.query.type
                "
                v-if="infoObj.next"
              >
                下一条：{{
                  infoObj.next.title +
                  (infoObj.next.itemNo
                    ? "(项目编号" + infoObj.next.itemNo + ")"
                    : "")
                }}
              </router-link>
              <!-- <router-link to="#">上一条：xxxx</router-link>
              <router-link to="#">下一条：xxxx</router-link>-->
            </div>
            <div class="f-right">
              <router-link :to="'/notice?key='+$route.query.type" class="arows">返回 &nbsp; >></router-link>
            </div>
          </div>
        </div>
        <!-- <div class="detailR fl">
                    <div class="detailR_tit">相关推荐</div>
                    <ul class="detailR_list fontsize16">
                        <li v-for="(item, index) in topList" :key="index">
                            <router-link
                                :to="
                                    $route.query.type === 'Bidding'
                                        ? '/notice-details?type=' +
                                          $route.query.type +
                                          '&id=' +
                                          item.tenderId
                                        : '/notice-details?type=' +
                                          $route.query.type +
                                          '&id=' +
                                          item.winningId
                                "
                            >
                                <div class="textSl1">
                                    {{ item.title }}
                                </div>
                            </router-link>
                        </li>
                    </ul>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/common/Banner.vue";
export default {
  components: {
    Banner,
  },
  data() {
    return {
      infoObj: {},
      topList: [],
      resBanners: [],
      fileUrlHeader: this.$store.state.fileUrlHeader,
      type: "Bidding",
    };
  },
  mounted() {
    // console.log(this.$route.query);
    // this.type = this.$route.query.type
    this.getinfo();
    // 获取banner
    this.getBanners();
  },
  methods: {
    // 时间转换
    onConversion(date) {
      // console.log(date);
      var nDate = new Date(date.replace(new RegExp(/-/gm), "/"));
      // let nDate = new Date(date),
      var y = nDate.getFullYear(),
        m = ("0" + (nDate.getMonth() + 1)).slice(-2),
        d = ("0" + nDate.getDate()).slice(-2);
      // console.log(date)
      return y + "-" + m + "-" + d;
    },
    async getinfo() {
      let resQuery = this.$route.query;
      let res, resTop;

      if (resQuery.type === "Bidding") {
        res = await this.$request.getreportdetail({
          tenderId: this.$route.query.id,
        });
        let resNext = await this.$request.getreportList({
          pageNo: resQuery.index - 0 + 1,
          pageSize: 1,
        });
        let resPrev = "";
        if (resQuery.index - 0 - 1 >= 0) {
          resPrev = await this.$request.getreportList({
            pageNo: resQuery.index - 0 - 1,
            pageSize: 1,
          });
        }
        // 推荐招标公告
        // resTop = await this.$request.postInfoTenderTop();
        this.infoObj = res.data.infoTender;
        this.infoObj.releaseTime = this.onConversion(
          res.data.infoTender.releaseTime
        );

        // console.log('resNext',resNext.total)
        this.infoObj.next =
          resQuery.index - 0 + 1 <= resNext.total
            ? {
                id: resNext.rows[0].tenderId,
                title: resNext.rows[0].title,
                itemNo: resNext.rows[0].itemNo,
                index: resQuery.index - 0 + 1,
              }
            : "";
        this.infoObj.prev =
          resQuery.index - 1 > 0
            ? {
                id: resPrev.rows[0].tenderId,
                title: resPrev.rows[0].title,
                itemNo: resPrev.rows[0].itemNo,
                index: resQuery.index - 0 - 1,
              }
            : "";
      } else {
        res = await this.$request.getinfoTwDet({
          winningId: this.$route.query.id,
        });
        let resNext = await this.$request.getinfoTwList({
          pageNo: resQuery.index - 0 + 1,
          pageSize: 1,
        });
        let resPrev = "";
        if (resQuery.index - 0 - 1 >= 0) {
          resPrev = await this.$request.getinfoTwList({
            pageNo: resQuery.index - 0 - 1,
            pageSize: 1,
          });
        }
        // 推荐中标公告
        // resTop = await this.$request.postInfoTenderWinningTop();
        // console.log('resNext',resNext)
        this.infoObj = res.data;
        this.infoObj.releaseTime = this.onConversion(res.data.releaseTime);
        this.infoObj.next =
          resQuery.index - 0 + 1 <= resNext.total
            ? {
                id: resNext.rows[0].winningId,
                title: resNext.rows[0].title,
                itemNo: resNext.rows[0].itemNo,
                index: resQuery.index - 0 + 1,
              }
            : "";
        this.infoObj.prev =
          resQuery.index - 1 > 0
            ? {
                id: resPrev.rows[0].winningId,
                title: resPrev.rows[0].title,
                itemNo: resPrev.rows[0].itemNo,
                index: resQuery.index - 0 - 1,
              }
            : "";
      }
      this.infoObj.fileUrl = JSON.parse(this.infoObj.fileUrl);
      // console.log(JSON.parse(this.infoObj.fileUrl))

      // this.infoObj = res.data;
      // this.topList = resTop.rows;
      // console.log(res);
      // console.log(resTop);
      // console.log(this.infoObj);
    },
    // 获取banner
    async getBanners() {
      let res = await this.$request.getImageAdvertisingList({
        advertisingPosition: "5",
      });
      if (res.code === 200) {
        this.resBanners = [];
        this.resBanners = res.rows;
      }
    },
  },
  watch: {
    // 监听路由发生改变
    $route: {
      handler(newVal) {
        if (newVal.query.id) this.getinfo();
      },
    },
  },
};
</script>
<style lang="scss">
img {
  max-width: 100% !important;
  display: block;
}
</style>
<style lang="scss" scoped>
@import "../../assets/css/notice.css";
/deep/table tr td {
  border: 1pt solid windowtext;
}
.mainBodyA {
  margin: 0 auto 1.56vw;
}

.noticeDetails {
  padding: 2.08vw 0 0 0;
}

.detailcon_con {
  /deep/ p {
    line-height: 1.4;
    margin: 0.83vw 0;
    /deep/ img {
      max-width: 100%;
      display: block;
    }
  }

  /deep/ img {
    max-width: 100%;
    display: block;
  }
}
/* .filecon{
            position: absolute;
            bottom: 7.81vw;
        } */

.detailcon {
  width: 100%;
  min-height: 31.25vw;
  height: auto;
  background-color: #ffffff;
  box-shadow: 0vw 0.05vw 0.36vw 0vw rgba(0, 0, 0, 0.09);
  padding: 1.56vw 1.56vw 7.81vw;
  box-sizing: border-box;
  position: relative;

  .detailcon_time {
    margin-top: 1.04vw;
    padding: 0 0 1.04vw 0;
    border-bottom: 0.05vw solid #eeeeee;
  }
}

.detailcon_time {
  margin-top: 1.04vw;
  padding: 0 0 1.04vw 0;
  border-bottom: 0.05vw solid #eeeeee;
}

.detailcon_tit {
  font-size: 0.94vw;
  padding-left: 0.52vw;
  line-height: 1.04vw;
  border-left: 0.52vw solid #f3ca00;
}

.detail_file {
  width: 100%;
  height: 2.08vw;
  line-height: 2.08vw;
  border: solid 0.05vw #eeeeee;
  margin-bottom: 0.52vw;
  /* position: absolute;
            bottom: 1.56vw;
            left: 1.56vw; */
}

.detail_file_tit {
  width: 5.42vw;
  text-align: center;
  background: #cccccc;
}

.detail_file_f {
  color: #f3ca00;
  font-size: 0.73vw;
  text-decoration: underline;
  padding-left: 0.52vw;
}

.c-footer {
  padding: 1.56vw 0;
  margin: 0;
  border-top: 0.05vw solid #cccccc;
  width: 59.38vw;
  position: absolute;
  bottom: 0;
  left: 1.56vw;

  .f-left {
    float: left;
    width: 36.46vw;
    a {
      display: inline-block;
      font-size: 0.83vw;
      color: #666666;
      padding: 0.52vw 0;
      width: 36.46vw;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      &:hover {
        color: #f3ca00;
      }
    }
  }

  .f-right {
    float: right;
    width: 6.77vw;
    .arows {
      width: 6.77vw;
      height: 1.56vw;
      line-height: 1.56vw;
      margin-top: 1.35vw;
      background-color: #f3ca00;
      color: #ffffff;
      text-align: center;
      cursor: pointer;
    }
  }
}
.detailR {
  width: 14.9vw;
  height: 13.91vw;
  background-color: #ffffff;
  border: solid 0.05vw #eeeeee;
  margin-left: 1.56vw;
}

.detailR_tit {
  width: 100%;
  height: 2.86vw;
  line-height: 2.86vw;
  color: #fff;
  text-align: center;
  background-color: #f3ca00;
}

.detailR_list {
  padding: 1.04vw;
  box-sizing: border-box;
}

.detailR_list > li {
  line-height: 1.56vw;
  position: relative;
  padding-left: 0.94vw;
}

.detailR_list > li::before {
  width: 0.47vw;
  height: 0.47vw;
  content: "";
  display: block;
  left: 0;
  top: 0.52vw;
  position: absolute;
  background-color: #eeeeee;
  border-radius: 50%;
}

@media (min-width: 1920px) {
  /deep/table tr td {
    border: 1pt solid windowtext;
  }
  .mainBodyA {
    margin: 0 auto 30px;
  }

  .noticeDetails {
    padding: 40px 0 0 0;
  }

  .detailcon_con {
    /deep/p {
      line-height: 1.4;
      margin: 16px 0;
      /deep/ img {
        max-width: 100%;
        display: block;
      }
    }

    /deep/ img {
      max-width: 100%;
      display: block;
    }
  }
  /* .filecon{
            position: absolute;
            bottom: 150px;
        } */

  .detailcon {
    width: 100%;
    min-height: 600px;
    height: auto;
    background-color: #ffffff;
    box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.09);
    padding: 30px 30px 150px;
    box-sizing: border-box;
    position: relative;

    .detailcon_time {
      margin-top: 20px;
      padding: 0 0 20px 0;
      border-bottom: 1px solid #eeeeee;
    }
  }

  .detailcon_time {
    margin-top: 20px;
    padding: 0 0 20px 0;
    border-bottom: 1px solid #eeeeee;
  }

  .detailcon_tit {
    font-size: 18px;
    padding-left: 10px;
    line-height: 20px;
    border-left: 10px solid #f3ca00;
  }

  .detail_file {
    width: 100%;
    height: 40px;
    line-height: 40px;
    border: solid 1px #eeeeee;
    margin-bottom: 10px;
    /* position: absolute;
            bottom: 30px;
            left: 30px; */
  }

  .detail_file_tit {
    width: 104px;
    text-align: center;
    background: #cccccc;
  }

  .detail_file_f {
    color: #f3ca00;
    font-size: 14px;
    text-decoration: underline;
    padding-left: 10px;
  }

  .c-footer {
    padding: 30px 0;
    margin: 0;
    border-top: 1px solid #cccccc;
    width: 1140px;
    position: absolute;
    bottom: 0;
    left: 30px;

    .f-left {
      float: left;
      width: 700px;
      a {
        display: inline-block;
        font-size: 16px;
        color: #666666;
        padding: 10px 0;
        width: 700px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        &:hover {
          color: #f3ca00;
        }
      }
    }

    .f-right {
      float: right;
      width: 130px;
      .arows {
        width: 130px;
        height: 30px;
        line-height: 30px;
        margin-top: 26px;
        background-color: #f3ca00;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .detailR {
    width: 286px;
    height: 267px;
    background-color: #ffffff;
    border: solid 1px #eeeeee;
    margin-left: 30px;
  }

  .detailR_tit {
    width: 100%;
    height: 55px;
    line-height: 55px;
    color: #fff;
    text-align: center;
    background-color: #f3ca00;
  }

  .detailR_list {
    padding: 20px;
    box-sizing: border-box;
  }

  .detailR_list > li {
    line-height: 30px;
    position: relative;
    padding-left: 18px;
  }

  .detailR_list > li::before {
    width: 9px;
    height: 9px;
    content: "";
    display: block;
    left: 0;
    top: 10px;
    position: absolute;
    background-color: #eeeeee;
    border-radius: 50%;
  }
}
</style>
